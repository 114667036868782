import { MayBeNull } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { Identifier } from 'dnd-core'
import { memo, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDrag, useDragLayer, useDrop, XYCoord } from 'react-dnd'

import { Flex } from 'components/common/flex/Flex'
import { showAppPickerModal } from 'pages/project/components/canvas/components/appPikerModal/AppPickerModal'
import { Placeholder } from 'pages/project/components/canvas/components/placeholder/Placeholder'
import { useDragScrolling } from 'pages/project/components/canvas/hooks/useDragScrolling'
import { DragItem } from 'pages/project/components/canvas/linearCanvas/components/item/DragItem'
import { showAddEditActivityModal } from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/AddEditActivityModal'
import styles from 'pages/project/components/canvas/linearCanvas/components/phase/DragPhase.module.scss'
import { Phase } from 'pages/project/components/canvas/linearCanvas/components/phase/Phase'
import { LinearPhase, DnDItem, DnDPhase, DragContainerType, DropPosition } from 'pages/project/components/canvas/utils'
import { LinearDispatchContext } from 'providers/common/LinearGenericProvider'
import { ProcessType } from 'types/projects/projects'
import { PhaseItem } from 'types/projects/workflow'
import { isEqualEmails } from 'utils/common'

interface Props {
  tasks: PhaseItem[]
  column: LinearPhase
  index: number
  projectId: string
  selectedCanvas: ProcessType
  isDraggingDisabled: boolean
  isItemsDraggingDisabled: boolean
  isOwnerOrGlobalManage: boolean
  isInactive?: boolean
  isWrikeConnected?: boolean
  isTemplate?: boolean
}

export const DragPhase = memo(
  ({
    tasks,
    column,
    index,
    projectId,
    selectedCanvas,
    isDraggingDisabled,
    isItemsDraggingDisabled,
    isInactive,
    isWrikeConnected,
    isTemplate,
    isOwnerOrGlobalManage,
  }: Props) => {
    const { dropOnColumn, moveColumn } = useContext(LinearDispatchContext)
    const phaseRef = useRef<HTMLDivElement>(null)
    const phaseItemRef = useRef<HTMLDivElement>(null)
    const {
      osContext: { userDetails },
    } = useOs()

    const { listenWindow, removeListenerWindow } = useDragScrolling()

    useEffect(() => {
      return () => removeListenerWindow()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isIAssignToThisPhase = isEqualEmails(column?.assignUser, userDetails.email)
    const [placeholderHeight, setPlaceholderHeight] = useState<MayBeNull<number>>(null)

    const isSomeItemDragging = useDragLayer(monitor => {
      if (monitor.getItemType() === DragContainerType.Phase) {
        return false
      }

      return monitor.isDragging()
    })

    const isDnDDisabled = isInactive || isDraggingDisabled || isItemsDraggingDisabled
    const phaseDropDisable = !isOwnerOrGlobalManage || isDnDDisabled
    const itemDropDisable = (!isOwnerOrGlobalManage && !isIAssignToThisPhase) || isDnDDisabled

    const openAppPickerModal = useCallback(
      (phaseId: string) => {
        showAppPickerModal({
          phaseId,
          projectId,
          selectedCanvas,
          isTemplate,
        })
      },
      [isTemplate, projectId, selectedCanvas],
    )

    const openActivityPickerModal = useCallback(
      (phaseId: string) => {
        showAddEditActivityModal({
          projectId,
          phaseId,
          isWrikeConnected,
          isTemplate,
        })
      },
      [isTemplate, isWrikeConnected, projectId],
    )

    const [{ phaseHandlerId }, dropPhase] = useDrop<
      DnDPhase,
      void,
      {
        phaseHandlerId: MayBeNull<Identifier>
        isOverPhase: boolean
      }
    >(
      {
        accept: DragContainerType.Phase,
        collect(monitor) {
          return {
            phaseHandlerId: monitor.getHandlerId(),
            isOverPhase: monitor.isOver({ shallow: true }),
          }
        },
        canDrop() {
          return !phaseDropDisable
        },
        hover(dndItem: DnDPhase, monitor) {
          if (!phaseRef.current) return
          const dragIndex = dndItem.index
          const hoverIndex = index

          // Don't replace items with themselves
          if (dragIndex === hoverIndex) {
            return
          }

          if (!monitor.canDrop()) {
            return
          }

          const hoverBoundingRect = phaseRef.current?.getBoundingClientRect()

          // Get horizontal middle
          const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2

          // Determine mouse position
          const clientOffset = monitor.getClientOffset()

          // Get pixels to the left
          const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left

          // Only perform the move when the mouse has crossed half of the items width
          // When dragging right, only move when the cursor is past 50%
          // When dragging left, only move when the cursor is before 50%

          // Dragging right
          if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
            return
          }

          // Dragging left
          if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
            return
          }

          moveColumn(dndItem.id, hoverIndex)
          dndItem.index = hoverIndex
        },

        drop(dndItem: DnDPhase, monitor) {
          removeListenerWindow()

          const didDrop = monitor.didDrop()
          const hoverIndex = index

          if (didDrop) return

          moveColumn(dndItem.id, hoverIndex, true)
        },
      },
      [moveColumn, phaseDropDisable],
    )

    const [{ isDragging: isPhaseDragging }, dragPhase, previewPhase] = useDrag(
      {
        type: DragContainerType.Phase,
        item: (): DnDPhase => {
          listenWindow()
          return { index, id: column.id }
        },
        collect: monitor => ({
          isDragging: monitor.isDragging(),
        }),
        canDrag: () => !isInactive && isOwnerOrGlobalManage && !isDraggingDisabled,
      },
      [isInactive, isDraggingDisabled, isOwnerOrGlobalManage],
    )

    previewPhase(dropPhase(phaseRef))

    const [{ isOverItem }, dropPhaseItem] = useDrop(
      () => ({
        accept: DragContainerType.Item,
        canDrop() {
          return !itemDropDisable
        },
        hover(dndItem: DnDItem, monitor) {
          if (!monitor.canDrop()) {
            return
          }

          setPlaceholderHeight(dndItem.height ?? null)
        },
        drop(dndItem: DnDItem, monitor) {
          // if dropPhaseItem was already handled
          const didDrop = monitor.didDrop()

          if (didDrop) return
          // can be dropped only at the bottom of the column (placeholder is currently visible only there).

          dropOnColumn(column.id, dndItem, DropPosition.Below)
        },
        collect: monitor => ({
          isOverItem: monitor.isOver({ shallow: true }) && monitor.canDrop(),
        }),
      }),
      [dropOnColumn, column.id, itemDropDisable],
    )
    dropPhaseItem(phaseItemRef)

    return (
      <Flex
        ref={phaseRef}
        className={clsx(styles.dragPhaseWrapper)}
        data-testid={`drag-phase-container-${column.id}`}
        gap={20}
      >
        {isPhaseDragging && <Placeholder className={clsx(styles.phasePlaceholder)} />}
        <Phase
          variant="primary"
          isEditable
          toggleAppPickerModal={openAppPickerModal}
          toggleActivityPickerModal={openActivityPickerModal}
          index={index}
          column={column}
          dragRef={dragPhase}
          dragHandlerId={phaseHandlerId}
          isInactive={isInactive}
          isDraggingDisabled={isDraggingDisabled}
          projectId={projectId}
          isWrikeConnected={isWrikeConnected}
          isTemplate={isTemplate}
          isOwnerOrGlobalManage={isOwnerOrGlobalManage}
        >
          <Flex
            direction="column"
            gap={12}
            className={clsx(styles.column, { [styles.isSomeItemDragging]: isSomeItemDragging })}
            ref={phaseItemRef}
            data-testid="phase-droppable-container"
          >
            {tasks.map((t, i) => (
              <DragItem
                projectId={projectId}
                task={t}
                key={t.id}
                index={i}
                isIAssignToThisPhase={isIAssignToThisPhase}
                isDraggingDisabled={isItemsDraggingDisabled}
                isInactive={isInactive}
                isWrikeConnected={isWrikeConnected}
                isTemplate={isTemplate}
                isOwnerOrGlobalManage={isOwnerOrGlobalManage}
              />
            ))}

            {isOverItem && <Placeholder height={placeholderHeight} />}
          </Flex>
        </Phase>
      </Flex>
    )
  },
)
