import {
  WppTypography,
  WppIconLink,
  WppButton,
  WppDivider,
  WppTooltip,
  WppIconRemoveCircle,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useFormContext, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import * as zod from 'zod'

import { Flex } from 'components/common/flex/Flex'
import { FormInput } from 'components/form/formInput/FormInput'
import { useForm } from 'hooks/form/useForm'
import styles from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/AppActivityItem.module.scss'
import {
  externalLink as externalLinkSchema,
  getFileFromUrl,
  getProjectIdFromUrl,
  MAX_LINKS_COUNT,
} from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { ExternalLink } from 'types/projects/workflow'
import { routesManager } from 'utils/routesManager'

interface Props {
  isEmbedded?: boolean
  disabled?: boolean
  existingCount?: number
  projectId: string
}

const addValidationSchema = zod
  .object({
    externalLink: externalLinkSchema,
  })
  .passthrough()

export const ActivityExternalLinksControl = ({ isEmbedded, disabled, projectId, existingCount = 0 }: Props) => {
  const addForm = useForm({
    defaultValues: { externalLink: { url: '', alias: '' } },
    validationSchema: addValidationSchema,
  })

  const {
    formState: { isValid },
    reset,
    handleSubmit,
  } = addForm

  const { getValues: getListValues, watch: watchList, setValue: setListValue } = useFormContext()

  const { t } = useTranslation()

  const externalLinks = watchList('externalLinks')

  const handleRemoveLink = (index: number) => {
    if (disabled) return

    const { externalLinks } = getListValues()
    setListValue('externalLinks', [...externalLinks.filter((t: ExternalLink, i: number) => i !== index)])
  }

  const handleAddLink = handleSubmit(async ({ externalLink }) => {
    const { externalLinks: oldLinks } = getListValues()
    reset()
    setListValue('externalLinks', [...oldLinks, externalLink])
  })

  // existing links + new links
  const linksLimit = MAX_LINKS_COUNT - existingCount

  return (
    <Flex direction="column" gap={12}>
      <FormProvider {...addForm}>
        <Flex gap={24} direction={isEmbedded ? 'row' : 'column'}>
          <FormInput
            name="externalLink.alias"
            className={styles.grow}
            labelConfig={{ text: !isEmbedded ? t('project.links_modal.field_name') : '' }}
            placeholder="Name your URL"
            data-testid="add-activity-external-link-name"
            disabled={disabled}
          />
          <FormInput
            name="externalLink.url"
            className={styles.grow}
            labelConfig={{ text: !isEmbedded ? t('project.links_modal.field_url') : '' }}
            placeholder="eg. https://www.website..."
            data-testid="add-activity-external-link-url"
            disabled={disabled}
            required
          />
          <WppTooltip
            text={t('product.activity.btn_add_external_link_max')!}
            config={{ trigger: externalLinks.length >= linksLimit ? 'mouseenter' : 'manual', hideOnClick: false }}
          >
            <div>
              <WppButton
                variant="secondary"
                onClick={handleAddLink}
                disabled={!isValid || externalLinks.length >= linksLimit}
                data-testid="activity-external-link-add"
              >
                {t('product.activity.btn_add_external_link')}
              </WppButton>
            </div>
          </WppTooltip>
        </Flex>
      </FormProvider>

      {!!externalLinks.length && <WppDivider />}

      <Flex className={styles.externalLinkList} gap={8}>
        {externalLinks.map((link: ExternalLink, index: number) => {
          const urlFile = getFileFromUrl(link.url)
          const urlProjectId = getProjectIdFromUrl(link.url)
          const isTheSameProject = urlProjectId === projectId

          return (
            <Flex
              direction="row"
              gap={8}
              align="center"
              justify="center"
              className={styles.externalLinksContainer}
              key={index}
              data-testid="link-in-list"
            >
              <WppIconLink />
              <Flex direction="column" className={styles.overflow}>
                {link.alias && <WppTypography type="s-body">{link.alias}</WppTypography>}

                <Link
                  to={
                    urlFile && isTheSameProject
                      ? {
                          pathname: routesManager.project.files.getURL({ id: urlProjectId }),
                          search: `?file=${urlFile}`,
                        }
                      : link.url
                  }
                  className={clsx(styles.link, styles.textBreak)}
                  target={!isTheSameProject ? '_blank' : undefined}
                  rel={!isTheSameProject ? 'noopener noreferrer' : undefined}
                >
                  {link.url}
                </Link>
              </Flex>
              <Flex justify="end" className={styles.closeContainer}>
                <WppIconRemoveCircle
                  onClick={() => handleRemoveLink(index)}
                  className={styles.closeIcon}
                  data-testid="remove-link-icon"
                />
              </Flex>
            </Flex>
          )
        })}
      </Flex>
    </Flex>
  )
}
