import styles from 'components/svg/common.module.scss'

export const SvgNoTimeline = (props: JSX.IntrinsicElements['svg']) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={121} height={120} fill="none" {...props}>
    <circle cx={60.5} cy={60} r={60} className={styles.fillPrimary100} />
    <path
      className={styles.fillPrimary500}
      d="M38 24H23v72h15V24ZM58 55.5H43V96h15V55.5ZM63 37.5h15V96H63V37.5ZM98 69H83v27h15V69Z"
    />
    <mask
      id="a"
      width={75}
      height={72}
      x={23}
      y={24}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path
        className={styles.fillPrimary500}
        d="M38 24H23v72h15V24ZM58 55.5H43V96h15V55.5ZM63 37.5h15V96H63V37.5ZM98 69H83v27h15V69Z"
      />
    </mask>
    <g mask="url(#a)">
      <path
        className={styles.fillBrandDisabled}
        d="M83 63h15v24H83zM61.25 32.25h18v34.5h-18zM41.75 32.25h18V75h-18zM21.5 17.25h18V60h-18z"
      />
      <path fill="#fff" d="M21.5 18h18v23.25h-18zM41.75 32.25h18V60h-18zM61.25 32.25h18V55.5h-18zM83 63h15v12H83z" />
    </g>
    <path className={styles.fillPrimary700} d="M21.5 96h78v1.5h-78z" />
    <circle cx={12.096} cy={57} r={1.5} className={styles.fillBrandDisabled} />
    <circle cx={94.25} cy={52.5} r={1.5} className={styles.fillPrimary500} />
    <path
      className={styles.fillPrimary200}
      d="M59.503 11.24c.266-.987 1.728-.987 1.994 0l.14.518c.096.358.393.635.77.716l.29.063a.98.98 0 0 1 0 1.926l-.29.063a1.011 1.011 0 0 0-.77.716l-.14.518c-.266.987-1.728.987-1.994 0l-.14-.518a1.011 1.011 0 0 0-.77-.716l-.29-.063a.98.98 0 0 1 0-1.926l.29-.063c.377-.082.674-.358.77-.716l.14-.518Z"
    />
    <circle cx={91.25} cy={29.654} r={2.25} className={styles.fillBrandDisabled} />
    <path
      className={styles.fillBrandHover}
      d="M98.254 34.675c.333-1.233 2.16-1.233 2.492 0l.174.647c.121.448.493.794.964.895l.362.079a1.224 1.224 0 0 1 0 2.408l-.362.079a1.265 1.265 0 0 0-.964.895l-.174.647c-.332 1.233-2.16 1.233-2.492 0l-.174-.647a1.264 1.264 0 0 0-.963-.895l-.363-.079a1.224 1.224 0 0 1 0-2.408l.363-.079c.47-.101.842-.447.963-.895l.174-.647Z"
    />
  </svg>
)
