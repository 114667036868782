import { NavigationTree } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { createContext, PropsWithChildren } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { useFetchWorkflowApiFluid, useFetchWorkflowApiLinear } from 'api/canvas/queries/useFetchWorkflowApi'
import { TemplateDetail } from 'api/templates/fetchers/fetchWorkflowTemplateByIdApi'
import { useFetchWorkflowTemplateByIdApi } from 'api/templates/queries/useFetchWorkflowTemplateByIdApi'
import { TemplateSkeleton } from 'pages/components/templateSkeleton/TemplateSkeleton'
import { LinearData } from 'pages/project/components/canvas/utils'
import { MayBeNull } from 'types/common/utils'
import { ProcessType } from 'types/projects/projects'
import { FluidWorkflow } from 'types/projects/workflow'

export const LOAD_ALL = 100_000

interface ProjectProps {
  tree: NavigationTree
  template: TemplateDetail
  isTemplateLoading: boolean
  linearData: LinearData
  isLinearLoading: boolean
  fluidData: MayBeNull<FluidWorkflow>
  isFluidLoading: boolean
  isOwner: boolean
}

export const TemplateContext = createContext<ProjectProps>(null!)

export const TemplateProvider = ({ children }: PropsWithChildren<{}>) => {
  const { templateId } = useParams()

  const { osContext } = useOs()
  const { navigationTree } = osContext

  const { data: template, isLoading: isTemplateLoading } = useFetchWorkflowTemplateByIdApi({
    params: { id: templateId! },
    enabled: !!templateId,
  })

  const { data: linearData, isLoading: isLinearLoading } = useFetchWorkflowApiLinear({
    params: { id: templateId! },
    enabled: !!templateId && template?.processType === ProcessType.LINEAR,
  })

  const { data: fluidData, isLoading: isFluidLoading } = useFetchWorkflowApiFluid({
    params: { id: templateId! },
    enabled: !!templateId && template?.processType === ProcessType.FLUID,
  })

  if (isTemplateLoading && !template) {
    return <TemplateSkeleton />
  }

  if (!template || !navigationTree) {
    return <Navigate replace to="/404" />
  }

  const isOwner = template.createdBy?.id === osContext.userDetails.id

  return (
    <TemplateContext.Provider
      value={{
        tree: navigationTree,
        template,
        isTemplateLoading,
        isLinearLoading,
        linearData,
        isOwner,
        fluidData,
        isFluidLoading,
      }}
    >
      {children}
    </TemplateContext.Provider>
  )
}
