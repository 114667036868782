import { WppSkeleton } from '@platform-ui-kit/components-library-react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useProjectTimelineApi } from 'api/projects/queries/useProjectTimelineApi'
import { EmptyState } from 'components/common/emptyState/EmptyState'
import { Flex } from 'components/common/flex/Flex'
import { SvgNoTimeline } from 'components/svg/SvgNoTimeline'
import { useProject } from 'hooks/useProject'
import { useToast } from 'hooks/useToast'
import { TimelineActionBar } from 'pages/project/components/timeline/components/timelineActionBar/TimelineActionBar'
import { TimelineChart } from 'pages/project/components/timeline/components/TimelineChart'
import styles from 'pages/project/components/timeline/Timeline.module.scss'
import { TimelineProvider } from 'pages/project/components/timeline/TimelineProvider'
import { initialTimelineFilters, timeline2Gantt, TimelineFilters } from 'pages/project/components/timeline/utils'
import { isFluidTimeline } from 'types/projects/timeline'

export const TimelineSkeleton = () => (
  <Flex className={styles.timelineContainer}>
    <WppSkeleton variant="rectangle" className={styles.timelineSkeleton} />
  </Flex>
)
export const TimelineEmpty = () => {
  const { t } = useTranslation()

  return (
    <Flex className={styles.timelineContainer}>
      <EmptyState
        noRecordsIcon={<SvgNoTimeline />}
        title={t('timeline.noData.title')}
        description={t('timeline.noData.description')}
      />
    </Flex>
  )
}

export const Timeline = () => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const { project } = useProject()
  const [filters, setFilters] = useState<TimelineFilters>(initialTimelineFilters)

  const { search } = filters
  const isFilterActive = !!search

  const {
    data: timeline,
    isLoading: isTimelineLoading,
    isError: isTimelineError,
  } = useProjectTimelineApi({
    params: { projectId: project.id, search },
    keepPreviousData: true,
  })

  useEffect(() => {
    if (isTimelineError) {
      showToast({
        type: 'error',
        message: t('common.generic_error'),
      })
    }
  }, [isTimelineError, showToast, t])

  const ganttTasks = useMemo(() => timeline2Gantt(timeline), [timeline])

  const noData =
    timeline &&
    ((isFluidTimeline(timeline) && timeline.tasks.length === 0) ||
      (!isFluidTimeline(timeline) && timeline.phases.length === 0))
  const isEmpty = !timeline || noData

  if (isTimelineLoading) {
    return <TimelineSkeleton />
  }

  if (isEmpty && !isFilterActive) {
    return <TimelineEmpty />
  }

  return (
    <>
      <TimelineActionBar setFilters={setFilters} />

      {isEmpty && (
        <EmptyState
          title={t('common.no_search_results')}
          filtersApplied={isFilterActive}
          description={t('common.no_results_description')}
          testToken="timeline"
        />
      )}

      {!isEmpty && (
        <TimelineProvider timeline={timeline!}>
          <TimelineChart tasks={ganttTasks} />
        </TimelineProvider>
      )}
    </>
  )
}
