import { MayBeNull, NavigationTree } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { createContext, PropsWithChildren, useContext } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { useFetchWorkflowApiFluid, useFetchWorkflowApiLinear } from 'api/canvas/queries/useFetchWorkflowApi'
import { useFetchProjectWrikeAccess } from 'api/projects/queries/useFetchProjectWrikeAccess'
import { useFetchWrikeWorkflows } from 'api/projects/queries/useFetchWrikeWorkflows'
import { useMembersListApi } from 'api/projects/queries/useMembersListApi'
import { useProjectApi } from 'api/projects/queries/useProjectApi'
import { useProjectViewSettingsApi } from 'api/projects/queries/useProjectViewSettingsApi'
import { useFetchTenantPreferenceApi } from 'api/tenant/queries/useFetchTenantPreferenceApi'
import { WrikeAuthContext } from 'auth/wrike/WrikeAuthProvider'
import { ProjectSkeleton } from 'pages/components/projectSkeleton/ProjectSkeleton'
import { LinearData } from 'pages/project/components/canvas/utils'
import { Members } from 'types/members/members'
import { ProcessType, Project, ProjectStatus } from 'types/projects/projects'
import { ProjectViewSettings } from 'types/projects/projectViewSettings'
import { FluidWorkflow } from 'types/projects/workflow'
import { WrikeWorkflows } from 'types/projects/WrikeWorkflows'
import { TenantPreference } from 'types/tenants/preference'

export const LOAD_ALL = 100_000

interface ProjectProps {
  tree: NavigationTree
  project: Project
  isProjectLoading: boolean
  viewSettings: ProjectViewSettings
  members: Members[]
  isMembersLoading: boolean
  linearData: LinearData
  isLinearLoading: boolean
  isFluidLoading: boolean
  fluidData: MayBeNull<FluidWorkflow>
  isInactive: boolean
  isRestrictedByWrike: boolean
  // if null - Project not connected to wrike, false - user not auth, true - user is auth
  isUserConnectWrike: MayBeNull<boolean>
  tenantPreference: MayBeNull<TenantPreference>
  wrikeWorkflows: MayBeNull<WrikeWorkflows>
  useExternalStatuses: boolean
}

export const ProjectContext = createContext<ProjectProps>(null!)

export const ProjectProvider = ({ children }: PropsWithChildren<{}>) => {
  const { projectId } = useParams()

  const { osContext } = useOs()
  const { navigationTree } = osContext
  const { isWrikeAuthenticated, isWrikeAuthLoading } = useContext(WrikeAuthContext)

  const { data: project, isLoading: isProjectLoading } = useProjectApi({
    params: { id: projectId! },
    enabled: !!projectId,
  })

  const { data: viewSettings, isLoading: isViewSettingsLoading } = useProjectViewSettingsApi({
    params: projectId!,
    enabled: !!projectId,
  })

  const { data: tenantPreference } = useFetchTenantPreferenceApi({
    enabled: !!projectId && !!project?.wrike?.isConnected,
  })

  const { data: wrikeWorkflows } = useFetchWrikeWorkflows({
    enabled: !!project?.wrike?.isConnected && tenantPreference?.useExternalStatuses,
  })

  const { data: status, isLoading: isWrikeCheckLoading } = useFetchProjectWrikeAccess({
    params: project?.wrike?.wrikeProjectId!,
    enabled: !!project?.wrike?.isConnected,
  })

  const isRestrictedByWrike = project?.wrike?.isConnected ? !isWrikeCheckLoading && status !== 204 : false

  const { data: members, isLoading: isMembersLoading } = useMembersListApi({
    params: { id: projectId! },
    enabled: !!projectId,
  })

  const { data: linearData, isLoading: isLinearLoading } = useFetchWorkflowApiLinear({
    params: { id: projectId! },
    enabled: !!projectId && project?.processType === ProcessType.LINEAR,
  })

  const { data: fluidData, isLoading: isFluidLoading } = useFetchWorkflowApiFluid({
    params: { id: projectId! },
    enabled: !!projectId && project?.processType === ProcessType.FLUID,
  })

  if ((isProjectLoading && !project) || isWrikeAuthLoading || isViewSettingsLoading) {
    return <ProjectSkeleton isCanvasLoading={isLinearLoading} />
  }

  if (!project || !navigationTree) {
    return <Navigate replace to="/404" />
  }

  const isUserConnectWrike = project.wrike?.isConnected ? !isWrikeAuthLoading && !!isWrikeAuthenticated : null

  const useExternalStatuses =
    !!project.wrike?.isConnected && !!wrikeWorkflows?.customStatuses && !!tenantPreference?.useExternalStatuses

  const isInactive =
    isUserConnectWrike === null
      ? project.status !== ProjectStatus.ACTIVE
      : isUserConnectWrike
      ? project.status !== ProjectStatus.ACTIVE
      : true

  return (
    <ProjectContext.Provider
      value={{
        tree: navigationTree,
        project,
        isProjectLoading: isProjectLoading,
        viewSettings: viewSettings!,
        members,
        isMembersLoading,
        linearData,
        isLinearLoading,
        isFluidLoading,
        fluidData,
        isUserConnectWrike,
        isInactive,
        tenantPreference,
        wrikeWorkflows,
        useExternalStatuses,
        isRestrictedByWrike,
      }}
    >
      {children}
    </ProjectContext.Provider>
  )
}
