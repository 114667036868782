import { WppButton, WppIconPlus, WppSkeleton } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useMemo, useState, useContext, useRef } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'

import { useCreatePhaseApi } from 'api/canvas/mutation/useCreatePhaseApi'
import { EmptyState } from 'components/common/emptyState/EmptyState'
import { Flex } from 'components/common/flex/Flex'
import { PageBackToTop } from 'components/common/table/PageBackToTop'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useTemplate } from 'hooks/useTemplate'
import { useToast } from 'hooks/useToast'
import styles from 'pages/project/components/canvas/Canvas.module.scss'
import { LinearSkeleton } from 'pages/project/components/canvas/components/canvasSkeleton/LinearSkeleton'
import { DragPhase } from 'pages/project/components/canvas/linearCanvas/components/phase/DragPhase'
import { StickySubheader } from 'pages/project/components/canvas/linearCanvas/components/stickySubheader/StickySubheader'
import { LinearValueContext } from 'providers/common/LinearGenericProvider'
import { queryClient } from 'providers/osQueryClient/utils'
import { AppPermissions } from 'types/permissions/permissions'
import { ProcessType } from 'types/projects/projects'
import { PhaseItem } from 'types/projects/workflow'

export const LinearTemplate = () => {
  const { isLinearLoading, template, isOwner } = useTemplate()
  const { linearData } = useContext(LinearValueContext)

  const { t } = useTranslation()

  const { showToast } = useToast()

  const { isPermitted } = useIsPermitted()
  const isOwnerOrGlobalManage = isOwner || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)
  const canCreateTemplate = isPermitted(AppPermissions.ORCHESTRATION_WORKFLOW_TEMPLATE_CREATE)
  const [hideSubHeader, setHideSubHeader] = useState(false)
  const canvasContainerRef = useRef<HTMLDivElement>(null)

  const [phasesAmount, setPhasesAmount] = useState(0)

  useMemo(() => {
    setPhasesAmount(linearData?.phaseOrder?.length ?? 0)
  }, [linearData?.phaseOrder?.length])

  const { mutateAsync: handleCreatePhase, isLoading: creatPhaseLoading } = useCreatePhaseApi()

  const createNewPhase = async () => {
    try {
      await handleCreatePhase({
        projectId: template.id,
        name: 'New Phase',
      })

      showToast({ type: 'success', message: t('project.canvas.toast.add_a_phase') })
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_LINEAR])
    } catch (e) {
      showToast({ type: 'error', message: t('project.canvas.toast.failed_operation_create', { query: 'phase' }) })

      console.error(e)
    }
  }

  const isItemsDraggingDisabled = useMemo(() => {
    if (linearData.phaseOrder.length > 1) return false
    const phase = linearData.phases[linearData.phaseOrder[0]]
    return phase?.itemIds.length < 2
  }, [linearData.phaseOrder, linearData.phases])

  const linearColumns = useMemo(() => {
    return linearData.phaseOrder.map(order => {
      const { id, name } = linearData.phases[order]
      return { id, name }
    })
  }, [linearData])

  return (
    <>
      <PageBackToTop scrollTopOffset={220} onChangeState={setHideSubHeader} />
      <Flex direction="column" className={styles.bodyLinear}>
        <Flex justify="end" className={styles.linearActionsRow}>
          {isLinearLoading ? (
            <WppSkeleton variant="rectangle" height="32px" width="120px" />
          ) : (
            <>
              {isOwnerOrGlobalManage && canCreateTemplate && (
                <Flex gap={12}>
                  {phasesAmount < 20 && !!phasesAmount && (
                    <WppButton
                      size="s"
                      onClick={createNewPhase}
                      disabled={isLinearLoading}
                      loading={creatPhaseLoading}
                      data-testid="add-phase-button"
                    >
                      <WppIconPlus slot="icon-start" />
                      {t('project.canvas.btn_add_phase')}
                    </WppButton>
                  )}
                </Flex>
              )}
            </>
          )}
        </Flex>
        {isLinearLoading ? (
          <div className={styles.canvasLinearTemplateWrapper}>
            <LinearSkeleton isTemplate={true} />
          </div>
        ) : (
          <>
            <StickySubheader
              columns={linearColumns}
              headerHidden={!hideSubHeader}
              containerRef={canvasContainerRef}
              columnClass={styles.columnCanvas}
            />

            <div className={styles.canvasLinearTemplateWrapper}>
              <DndProvider backend={HTML5Backend}>
                <Flex
                  className={clsx(styles.canvasTemplateContainer, { [styles.alignCenter]: !phasesAmount })}
                  ref={canvasContainerRef}
                  gap={20}
                  id="horizontal-scroll-dnd"
                >
                  {!phasesAmount && (
                    <EmptyState
                      title={t('template.canvas.empty_canvas_title')}
                      description={t('template.canvas.empty_canvas_description')}
                      testToken="app-data"
                    >
                      <WppButton
                        size="m"
                        onClick={createNewPhase}
                        disabled={isLinearLoading}
                        loading={creatPhaseLoading}
                        data-testid="add-phase-button"
                      >
                        <WppIconPlus slot="icon-start" />
                        {t('template.canvas.btn_new_phase')}
                      </WppButton>
                    </EmptyState>
                  )}
                  {linearData.phaseOrder.map((order, index) => {
                    const column = linearData.phases[order]
                    const tasks = column.itemIds.map(id => linearData.items[id] as PhaseItem)

                    return (
                      <DragPhase
                        key={column.id}
                        column={column}
                        tasks={tasks}
                        index={index}
                        projectId={template.id}
                        selectedCanvas={ProcessType.LINEAR}
                        isDraggingDisabled={linearData.phaseOrder.length === 1}
                        isItemsDraggingDisabled={isItemsDraggingDisabled}
                        isTemplate={true}
                        isOwnerOrGlobalManage={isOwnerOrGlobalManage}
                      />
                    )
                  })}
                </Flex>
              </DndProvider>
            </div>
          </>
        )}
      </Flex>
    </>
  )
}
