import { WppActionButton, WppButton, WppIconPin, WppIconPlus } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useEffect, useMemo, useState, useContext, useRef } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'

import { useCreatePhaseApi } from 'api/canvas/mutation/useCreatePhaseApi'
import { EmptyState } from 'components/common/emptyState/EmptyState'
import { Flex } from 'components/common/flex/Flex'
import { PageBackToTop } from 'components/common/table/PageBackToTop'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useProject } from 'hooks/useProject'
import { useToast } from 'hooks/useToast'
import styles from 'pages/project/components/canvas/Canvas.module.scss'
import { CanvasActionsSkeleton } from 'pages/project/components/canvas/components/canvasSkeleton/CanvasActionsSkeleton'
import { LinearSkeleton } from 'pages/project/components/canvas/components/canvasSkeleton/LinearSkeleton'
import { LockOverlay } from 'pages/project/components/canvas/components/lockOverlay/LockOverlay'
import { showSaveAsTemplateModal } from 'pages/project/components/canvas/components/saveAsTemplateModal/SaveAsTemplateModal'
import { useCanvasLock } from 'pages/project/components/canvas/hooks/useCanvasLock'
import { invalidateCanvas } from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { DragPhase } from 'pages/project/components/canvas/linearCanvas/components/phase/DragPhase'
import { StickySubheader } from 'pages/project/components/canvas/linearCanvas/components/stickySubheader/StickySubheader'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { LinearValueContext } from 'providers/common/LinearGenericProvider'
import { queryClient } from 'providers/osQueryClient/utils'
import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { ProcessType } from 'types/projects/projects'
import { PhaseItem } from 'types/projects/workflow'

export const LinearTab = () => {
  const { project, isLinearLoading, isInactive } = useProject()
  const { linearData } = useContext(LinearValueContext)

  const { t } = useTranslation()

  const { showToast } = useToast()

  const { hasRole } = useHasProjectRole()
  const { isPermitted } = useIsPermitted()
  const isOwnerOrGlobalManage = hasRole([ProjectRole.OWNER]) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)
  const canCreateTemplate = isPermitted(AppPermissions.ORCHESTRATION_WORKFLOW_TEMPLATE_CREATE)
  const [hideSubHeader, setHideSubHeader] = useState(false)
  const canvasContainerRef = useRef<HTMLDivElement>(null)

  const [phasesAmount, setPhasesAmount] = useState(0)
  const { isLocked, isLockLoading } = useCanvasLock(project.id)

  const isLockedPrevious = usePrevious(isLocked)

  useEffect(() => {
    if (isLockedPrevious === true && isLocked === false) {
      invalidateCanvas()
    }
  }, [isLocked, isLockedPrevious])

  useMemo(() => {
    setPhasesAmount(linearData?.phaseOrder?.length ?? 0)
  }, [linearData?.phaseOrder?.length])

  const { mutateAsync: handleCreatePhase, isLoading: creatPhaseLoading } = useCreatePhaseApi()

  const saveAsTemplate = () => {
    showSaveAsTemplateModal()
  }

  const createNewPhase = async () => {
    try {
      await handleCreatePhase({
        projectId: project.id,
        name: 'New Phase',
      })

      showToast({ type: 'success', message: t('project.canvas.toast.add_a_phase') })
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_LINEAR])
    } catch (e) {
      showToast({ type: 'error', message: t('project.canvas.toast.failed_operation_create', { query: 'phase' }) })

      console.error(e)
    }
  }

  const isLoading = isLinearLoading || isLockLoading
  const isSaveTemplateDisabled = isLoading || (project.processType === ProcessType.LINEAR && !phasesAmount)

  const isItemsDraggingDisabled = useMemo(() => {
    if (linearData.phaseOrder.length > 1) return false
    const phase = linearData.phases[linearData.phaseOrder[0]]
    return phase?.itemIds.length < 2
  }, [linearData.phaseOrder, linearData.phases])

  const linearColumns = useMemo(() => {
    return linearData.phaseOrder.map(order => {
      const { id, name } = linearData.phases[order]
      return { id, name }
    })
  }, [linearData])

  return (
    <>
      <PageBackToTop scrollTopOffset={220} onChangeState={setHideSubHeader} />
      <Flex direction="column" className={styles.bodyLinear}>
        <Flex justify="end" className={styles.linearActionsRow}>
          {isLoading ? (
            <CanvasActionsSkeleton />
          ) : (
            <>
              {isOwnerOrGlobalManage && (
                <Flex gap={12}>
                  {canCreateTemplate && (
                    <WppActionButton
                      onClick={saveAsTemplate}
                      disabled={isSaveTemplateDisabled}
                      hidden={project.processType === ProcessType.LINEAR && !phasesAmount}
                      data-testid="save-as-template-button"
                    >
                      <WppIconPin slot="icon-start" />
                      {t('project.canvas.btn_save_as_template')}
                    </WppActionButton>
                  )}

                  {phasesAmount < 20 && !!phasesAmount && (
                    <WppButton
                      size="s"
                      onClick={createNewPhase}
                      disabled={isLinearLoading || isInactive}
                      loading={creatPhaseLoading}
                      data-testid="add-phase-button"
                    >
                      <WppIconPlus slot="icon-start" />
                      {t('project.canvas.btn_add_phase')}
                    </WppButton>
                  )}
                </Flex>
              )}
            </>
          )}
        </Flex>
        {isLoading ? (
          <div className={styles.canvasLinearWrapper}>
            <LinearSkeleton />
          </div>
        ) : (
          <>
            {isLocked && <LockOverlay />}
            <StickySubheader
              columns={linearColumns}
              headerHidden={!hideSubHeader}
              containerRef={canvasContainerRef}
              columnClass={styles.columnCanvas}
            />

            <div className={styles.canvasLinearWrapper}>
              <DndProvider backend={HTML5Backend}>
                <Flex
                  className={clsx(styles.canvasContainer, { [styles.alignCenter]: !phasesAmount })}
                  ref={canvasContainerRef}
                  gap={20}
                  id="horizontal-scroll-dnd"
                >
                  {!phasesAmount && (
                    <EmptyState
                      title={t('project.canvas.empty_canvas_title')}
                      description={t('project.canvas.empty_canvas_description')}
                      testToken="app-data"
                    >
                      <WppButton
                        size="m"
                        onClick={createNewPhase}
                        disabled={isLinearLoading || isInactive}
                        loading={creatPhaseLoading}
                        data-testid="add-phase-button"
                      >
                        <WppIconPlus slot="icon-start" />
                        {t('project.canvas.btn_add_phase')}
                      </WppButton>
                    </EmptyState>
                  )}
                  {linearData.phaseOrder.map((order, index) => {
                    const column = linearData.phases[order]
                    const tasks = column.itemIds.map(id => linearData.items[id] as PhaseItem)

                    return (
                      <DragPhase
                        key={column.id}
                        column={column}
                        tasks={tasks}
                        index={index}
                        projectId={project.id}
                        selectedCanvas={project.processType}
                        isDraggingDisabled={linearData.phaseOrder.length === 1 || isInactive}
                        isItemsDraggingDisabled={isItemsDraggingDisabled || isInactive}
                        isInactive={isInactive}
                        isWrikeConnected={!!project.wrike?.isConnected}
                        isOwnerOrGlobalManage={isOwnerOrGlobalManage}
                      />
                    )
                  })}
                </Flex>
              </DndProvider>
            </div>
          </>
        )}
      </Flex>
    </>
  )
}
