import {
  WppAvatarGroup,
  WppButton,
  WppCard,
  WppIconEdit,
  WppIconExternalLink,
  WppIconPlus,
  WppListItem,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { HierarchyLevelType, TenantType } from '@wpp-open/core'
import { HierarchyCustomNodeType } from '@wpp-open/core/types/mapping/common'
import { DefaultHierarchyLevelType } from '@wpp-open/core/types/tenant'
import { useOs } from '@wpp-open/react'
import { RowClickedEvent } from 'ag-grid-community'
import clsx from 'clsx'
import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Avatar, getAvatarColor } from 'components/common/avatar/Avatar'
import { EmptyState } from 'components/common/emptyState/EmptyState'
import { Flex } from 'components/common/flex/Flex'
import { ColDef, TablePageInfinite } from 'components/common/table'
import { SvgWrikeLogo } from 'components/svg/WrikeLogo'
import { TableKey } from 'constants/table'
import { useHierarchy } from 'hooks/useHierarchy'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useStableCallback } from 'hooks/useStableCallback'
import { useWrike } from 'hooks/useWrike'
import { showCreateProjectModal } from 'pages/components/projectModal/CreateProjectModal'
import { showProjectEditModal } from 'pages/components/projectModal/EditProjectModal'
import { ProjectManageMenu } from 'pages/dashboard/components/projectManageMenu/ProjectManageMenu'
import { ProjectStatusIcon } from 'pages/dashboard/components/projectsCardView/components/projectStatus/ProjectStatusIcon'
import styles from 'pages/dashboard/components/projectsTableView/ProjectsTableVire.module.scss'
import { useProjectListLoader } from 'pages/dashboard/components/projectsTableView/useProjectListLoader'
import { ProjectTypeTag } from 'pages/dashboard/components/projectTypeTag/ProjectTypeTag'
import { useIsFiltersActive } from 'pages/dashboard/utils/useIsFiltersActive'
import { AppPermissions } from 'types/permissions/permissions'
import { Project, ProjectFilter, ProjectStatus } from 'types/projects/projects'
import { PhaseStatus } from 'types/projects/workflow'
import { fullName } from 'utils/common'
import { formatDate } from 'utils/dateFormat'
import { hasClosestInteractiveElement } from 'utils/dom'
import { routesManager } from 'utils/routesManager'

const NotDefined = () => (
  <WppTypography type="s-body" className={styles.notDefined}>
    -
  </WppTypography>
)

interface Props {
  filter: ProjectFilter
  setProjectsExist: (exist: boolean) => void
}

export const ProjectsTableView: FC<Props> = ({ filter, setProjectsExist }) => {
  const navigate = useNavigate()
  const { isWrikeAuthenticated } = useWrike()
  const { getWorkspaceTree, hierarchyOrder } = useHierarchy()
  const [isEmpty, setIsEmpty] = useState(false)
  const { osContext } = useOs()

  const isMultiTenant = osContext.tenant.tenantType === TenantType.Agency
  const fromCurrentTenant = useCallback((id: string) => osContext.tenant.id === id, [osContext])

  const { isWrikeAvailable } = useWrike()

  const { isPermitted } = useIsPermitted()
  const canCreateProject =
    isPermitted(AppPermissions.ORCHESTRATION_PROJECTS_CREATE) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const { t } = useTranslation()
  const { loader } = useProjectListLoader({ filter })
  const isFilterActive = useIsFiltersActive(filter)

  const {
    osContext: {
      tenant,
      userDetails: { dateLocale },
    },
  } = useOs()

  const gridOptions = {
    rowStyle: { cursor: 'pointer' },
  }

  const inProgressPhases = useCallback(
    (project?: Project) =>
      project?.phases
        ?.sort((a, b) => a.orderNumber - b.orderNumber)
        ?.filter(phase => phase.status === PhaseStatus.IN_PROGRESS),
    [],
  )

  const workspaceCell = useCallback(
    (nodeType: HierarchyLevelType, data?: Project) => {
      const tree = getWorkspaceTree(data?.contextWorkspace)
      const element = tree?.find(
        elem =>
          nodeType ===
          (elem.type.toLowerCase() === HierarchyCustomNodeType
            ? elem?.customTypeName?.toLowerCase()
            : elem.type.toLowerCase()),
      )

      if (element)
        return (
          <WppTypography type="s-body" className={styles.overflow} title={element?.name}>
            {element?.name}
          </WppTypography>
        )

      return <NotDefined />
    },
    [getWorkspaceTree],
  )

  const getActivePhasesString = useCallback(
    (data?: Project) =>
      inProgressPhases(data)?.map(
        (phase, index) => phase.name + (index !== inProgressPhases(data)!.length - 1 ? ', ' : ''),
      ),
    [inProgressPhases],
  )

  const isClientTenant = tenant.tenantType === TenantType.Client

  const columnDefs = useMemo<ColDef<Project>[]>(() => {
    return [
      {
        colId: 'status',
        width: 36,
        headerName: '',
        cellStyle: { paddingRight: 0 },
        cellRenderer: ({ data }) => <ProjectStatusIcon status={data!.status} />,
      },
      {
        colId: 'name',
        flex: 2.5,
        minWidth: 250,
        headerName: t('project.table.project_name')!,
        cellRenderer: ({ data }) => (
          <Flex direction="column" className={styles.nameWrapper}>
            <WppTypography
              type="s-body"
              title={data!.name}
              data-testid="table-project-name"
              className={styles.ellipsis}
            >
              {data!.name}
            </WppTypography>

            <WppTypography
              className={clsx(styles.ellipsis, styles.grey800)}
              type="xs-body"
              title={data!.name}
              data-testid="table-project-active-phase"
            >
              {getActivePhasesString(data)}
            </WppTypography>
          </Flex>
        ),
        tooltipValueGetter: ({ data }) => data?.name,
      },
      {
        colId: 'type',
        flex: 1.5,
        minWidth: 150,
        headerName: t('project.table.project_type')!,
        cellRenderer: ({ data }) => <ProjectTypeTag type={data!.type} className={styles.overflow} />,
        cellClassRules: {
          [styles.cell]: () => true,
        },
      },
      ...hierarchyOrder.reduce((prev, curr) => {
        if (isClientTenant && curr === DefaultHierarchyLevelType.Client.toLowerCase()) return prev
        return [
          ...prev,
          {
            colId: curr,
            flex: 1,
            hide: isMultiTenant,
            minWidth: 100,
            headerName: t(`modals.create_project.field_${curr}_label`, { defaultValue: curr })!,
            cellRenderer: ({ data }) => workspaceCell(curr, data),
          },
        ]
      }, [] as ColDef<Project>[]),
      {
        colId: 'tenant.id',
        flex: 1,
        hide: !isMultiTenant,
        headerName: 'Workspace',
        cellRenderer: ({ data }) => (
          <Flex gap={4} align="center">
            <WppTypography type="s-body" className={styles.projectTenantName}>
              {data?.tenant?.name}
            </WppTypography>
            {!fromCurrentTenant(data?.tenant?.id!) && <WppIconExternalLink />}
          </Flex>
        ),
      },
      {
        colId: 'startDate',
        flex: 1,
        minWidth: 100,
        headerName: t('common.start_date')!,
        valueFormatter: ({ data }) => (data?.startDate ? formatDate({ dateString: data.startDate, dateLocale })! : '-'),
      },
      {
        colId: 'endDate',
        flex: 1,
        minWidth: 100,
        headerName: t('common.end_date')!,
        valueFormatter: ({ data }) => (data?.endDate ? formatDate({ dateString: data.endDate, dateLocale })! : '-'),
      },
      {
        colId: 'owner',
        flex: 1.25,
        minWidth: 125,
        headerName: t('project.table.project_owners')!,
        cellClassRules: {
          [styles.avatarCell]: () => true,
        },
        cellRenderer: ({ data }) => (
          <>
            {data?.owners?.length === 1 ? (
              <Flex align="center" gap={16} className={styles.overflow}>
                <Avatar
                  className={styles.noShrink}
                  size="xs"
                  name={fullName(data?.owner?.firstname, data?.owner?.lastname)}
                  src={data?.owner?.avatarUrl ?? ''}
                  withTooltip
                  tooltipConfig={{ placement: 'top' }}
                />
              </Flex>
            ) : (
              <WppAvatarGroup
                className={styles.otherMembers}
                onClick={e => {
                  e.stopPropagation()
                }}
                maxAvatarsToDisplay={4}
                size="xs"
                withTooltip
                users={data?.owners?.map(owner => ({
                  name: fullName(owner.firstname, owner.lastname),
                  src: owner.avatarUrl ?? '',
                  color: getAvatarColor({ name: owner.firstname }),
                }))}
              />
            )}
          </>
        ),
      },
      {
        colId: 'wrike',
        headerName: t('project.table.wrike')!,
        flex: 0.8,
        minWidth: 80,
        hide: !isWrikeAvailable,
        cellRenderer: ({ data }) => {
          return data?.wrike?.isConnected ? <SvgWrikeLogo data-testid="wrike-logo" /> : null
        },
      },
      {
        cellRenderer: ({ data }) => (
          <>
            {fromCurrentTenant(data?.tenant?.id!) && (
              <ProjectManageMenu
                project={data!}
                securedChildren={
                  data!.status === ProjectStatus.ACTIVE && (
                    <WppListItem
                      onWppChangeListItem={() => showProjectEditModal({ project: data! })}
                      disabled={data!.wrike?.isConnected && !isWrikeAuthenticated}
                    >
                      <WppIconEdit slot="left" />
                      <WppTypography slot="label" type="s-body" data-testid="edit-action">
                        {t('project.list.btn_edit_project')}
                      </WppTypography>
                    </WppListItem>
                  )
                }
              />
            )}
          </>
        ),
        width: 64,
        minWidth: 64,
        resizable: false,
        pinned: 'right',
      },
    ]
  }, [
    t,
    hierarchyOrder,
    isMultiTenant,
    isWrikeAvailable,
    getActivePhasesString,
    isClientTenant,
    workspaceCell,
    fromCurrentTenant,
    dateLocale,
    isWrikeAuthenticated,
  ])

  const handleOnRowClicked = useStableCallback(({ event, data }: RowClickedEvent<Project>) => {
    const target = event?.target as HTMLElement

    if (data && !hasClosestInteractiveElement(target, ['.wpp-avatar-group'])) {
      if (isMultiTenant && !fromCurrentTenant(data?.tenant?.id!)) {
        window.open(
          `${data.tenant!.homeUrl}/orchestration${routesManager.project.workflow.getURL({
            id: data!.id,
          })}`,
          '_blank',
        )
        return
      }

      navigate(
        routesManager.project.workflow.getURL({
          id: data!.id,
        }),
      )
    }
  })

  const noRowsOverlayComponent = useCallback(() => {
    return (
      <EmptyState
        title={isFilterActive ? t('common.no_search_results') : t('project.list.empty_state_no_projects')}
        testToken="projects"
        filtersApplied={!!filter.search || isFilterActive}
        description={
          !!filter.search || isFilterActive
            ? t('common.no_results_description')
            : t('project.list.empty_state_no_projects_description')
        }
      >
        {!isFilterActive && canCreateProject && (
          <WppButton size="m" onClick={() => showCreateProjectModal()} data-testid="create-new-project">
            <WppIconPlus slot="icon-start" /> {t('dashboard.btn_add_project')}
          </WppButton>
        )}
      </EmptyState>
    )
  }, [canCreateProject, filter.search, isFilterActive, t])

  return (
    <WppCard className={clsx(styles.card, { [styles.emptyCard]: isEmpty })}>
      <TablePageInfinite
        gridOptions={gridOptions}
        tableKey={TableKey.PROJECT_LIST}
        loader={loader}
        cacheBlockSize={50}
        rowHeight={48}
        columnDefs={columnDefs}
        onRowClicked={handleOnRowClicked}
        noRowsOverlayComponent={noRowsOverlayComponent}
        headerHeight={isEmpty ? 0 : undefined}
        onLoadSuccess={({ isEmptySource }) => {
          setProjectsExist(!isEmptySource)
          setIsEmpty(isEmptySource)
        }}
        className={clsx({ [styles.hideBorder]: isEmpty })}
      />
    </WppCard>
  )
}
