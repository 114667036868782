import { projectApi } from 'api'
import { Timeline } from 'types/projects/timeline'

export interface Params {
  projectId: string

  // filters
  search?: string
}

export const fetchProjectTimelineApi = ({ projectId, ...params }: Params) =>
  projectApi.get<Timeline>(`/projects/${projectId}/tasks/timeline`, { params })
